import { useCallback, useLayoutEffect, useRef, useState } from 'react';

export type MediaQueries = MediaQueryListEvent['media'][];

export type MatchedMedia = MediaQueryList['matches'][];

export function useMatchMedia(
  queries: MediaQueries,
  defaultValues: MatchedMedia = [],
): MatchedMedia {
  if (typeof window === 'undefined') {
    return defaultValues;
  }

  const mediaQueryLists = useRef<MediaQueryList[]>(
    queries.map(query => window.matchMedia(query)),
  );

  const getValue = useCallback(
    () => mediaQueryLists.current.map(mql => mql.matches),
    [mediaQueryLists],
  );

  const [value, setValue] = useState<MatchedMedia>(getValue());

  useLayoutEffect(() => {
    function onChange() {
      setValue(getValue);
    }

    mediaQueryLists.current.forEach(mediaQueryList => {
      try {
        mediaQueryList.addEventListener('change', onChange);
      } catch {
        mediaQueryList.addListener(onChange); // Safari doesn't currently support addEventListener for matchMedia
      }
    });

    return () => {
      mediaQueryLists.current.forEach(mediaQueryList => {
        try {
          mediaQueryList.removeEventListener('change', onChange);
        } catch {
          mediaQueryList.removeListener(onChange); // Safari doesn't currently support addEventListener for matchMedia
        }
      });
    };
  }, []);

  return value;
}
